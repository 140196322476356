import Immutable from 'immutable';
import { createAjaxWithCredentials } from '../_shared/utils/ajax.ts';
import { Cache } from '../_shared/utils/cache.ts';
import { getMilliseconds } from '../_shared/utils/dateTime/timeUtils.ts';
import { IRequestContext, createRestProvider } from '../_shared/utils/restProvider.ts';
import { getUrlFactory } from '../_shared/utils/urlFactory.ts';
import {
  getTaxonomyGroupDummyKeyByProjectId,
  getTaxonomyGroupKeyById,
  getTaxonomyGroupsKeyByProjectId,
  touchTaxonomyGroupAndItsDependencies,
} from './cacheKeys/taxonomyCacheUtils.ts';
import { ITaxonomyRepository } from './interfaces/ITaxonomyRepository.type.ts';
import { RepositoryWithContext } from './interfaces/repository.type.ts';
import { ITaxonomyGroupServerModel } from './serverModels/contentModels/TaxonomyGroupServerModel.type.ts';

const restProvider = createRestProvider(createAjaxWithCredentials());
const cacheExpiration = getMilliseconds({ minutes: 5 });

export const taxonomyRepository: RepositoryWithContext<ITaxonomyRepository> = {
  getTaxonomyGroups(
    requestContext: IRequestContext,
    abortSignal?: AbortSignal,
  ): Promise<Array<ITaxonomyGroupServerModel>> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/taxonomy/`;
    return Cache.cache(
      getTaxonomyGroupsKeyByProjectId(requestContext.projectId),
      [getTaxonomyGroupDummyKeyByProjectId(requestContext.projectId)],
      () => restProvider.get(url, null, abortSignal, requestContext),
      cacheExpiration,
    );
  },

  getTaxonomyGroup(
    requestContext: IRequestContext,
    taxonomyGroupId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ITaxonomyGroupServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/taxonomy/${taxonomyGroupId}`;
    return Cache.cache(
      getTaxonomyGroupKeyById(taxonomyGroupId, requestContext.projectId),
      [],
      () => restProvider.get(url, null, abortSignal, requestContext),
      cacheExpiration,
    );
  },

  archiveTaxonomyGroups(
    requestContext: IRequestContext,
    taxonomyGroupIds: Immutable.Set<Uuid>,
    abortSignal?: AbortSignal,
  ): Promise<any[]> {
    const deletePromises = taxonomyGroupIds.map((groupId: Uuid) =>
      this.archiveTaxonomyGroup(requestContext, groupId, abortSignal),
    );
    return Promise.all(deletePromises.toArray());
  },

  async archiveTaxonomyGroup(
    requestContext: IRequestContext,
    taxonomyGroupId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ITaxonomyGroupServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/taxonomy/${taxonomyGroupId}/archive`;
    try {
      const result = await restProvider.put(url, { archived: true }, abortSignal, requestContext);
      touchTaxonomyGroupAndItsDependencies(requestContext.projectId, taxonomyGroupId);
      return result;
    } catch (e) {
      touchTaxonomyGroupAndItsDependencies(requestContext.projectId, taxonomyGroupId);
      throw e;
    }
  },

  restoreTaxonomyGroups(
    requestContext: IRequestContext,
    taxonomyGroupIds: Immutable.Set<Uuid>,
    abortSignal?: AbortSignal,
  ): Promise<any[]> {
    const restorePromises = taxonomyGroupIds.map((groupId: Uuid) =>
      this.restoreTaxonomyGroup(requestContext, groupId, abortSignal),
    );
    return Promise.all(restorePromises.toArray());
  },

  async restoreTaxonomyGroup(
    requestContext: IRequestContext,
    taxonomyGroupId: Uuid,
    abortSignal?: AbortSignal,
  ): Promise<ITaxonomyGroupServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(
      requestContext.projectId,
    )}/taxonomy/${taxonomyGroupId}/archive`;
    try {
      const result = await restProvider.put(url, { archived: false }, abortSignal, requestContext);
      touchTaxonomyGroupAndItsDependencies(requestContext.projectId, taxonomyGroupId);
      return result;
    } catch (e) {
      touchTaxonomyGroupAndItsDependencies(requestContext.projectId, taxonomyGroupId);
      throw e;
    }
  },

  async createTaxonomyGroup(
    requestContext: IRequestContext,
    taxonomyGroup: ITaxonomyGroupServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ITaxonomyGroupServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/taxonomy`;
    try {
      const result = await restProvider.post(url, taxonomyGroup, abortSignal, requestContext);
      touchTaxonomyGroupAndItsDependencies(requestContext.projectId, taxonomyGroup.id);
      return result;
    } catch (e) {
      touchTaxonomyGroupAndItsDependencies(requestContext.projectId, taxonomyGroup.id);
      throw e;
    }
  },

  async updateTaxonomyGroup(
    requestContext: IRequestContext,
    taxonomyGroup: ITaxonomyGroupServerModel,
    abortSignal?: AbortSignal,
  ): Promise<ITaxonomyGroupServerModel> {
    const url = `${getUrlFactory().getDraftProjectApiUrl(requestContext.projectId)}/taxonomy/${
      taxonomyGroup.id
    }`;
    try {
      const result = await restProvider.put(url, taxonomyGroup, abortSignal, requestContext);
      touchTaxonomyGroupAndItsDependencies(requestContext.projectId, taxonomyGroup.id);
      return result;
    } catch (e) {
      touchTaxonomyGroupAndItsDependencies(requestContext.projectId, taxonomyGroup.id);
      throw e;
    }
  },
};
